* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "DM Sans";
    font-size: 16px;
    line-height: 1.5;
    /* background-color: #344054; */
}

a {
    color: #333;
    text-decoration: none;
}

.loading h4 {
    font-size: 16px;
    font-weight: 600;
}

.loading {
    position: absolute;
    left: 45%;
    display: flex;
    align-items: center;
    height: 45vh;
}

.txt-center {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.txt-center h1 {
    font-weight: 700;
}

.txt-right {
    text-align: right;
}

.txt-cente {
    text-align: center;
}

.loader-img {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 100vh;
}

.ant-form-item-explain-error {
    font-size: 12px;
}


/* scrollbar start*/

::-webkit-scrollbar {
    width: 3px;
    display: none;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #dcdcdc;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #afb5d1;
    border-radius: 10px;
}

.scroll-table::-webkit-scrollbar {
    width: 7px !important;
    height: 7px !important;
    display: block !important;
}

.scroll-table table tbody tr td {
    padding: 5px !important;
}

/* scrollbar End*/



/*  Common Table & table head */



.common-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 60px 20px 60px;
}

.common-head .title h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 36px;
    color: #101828;
    white-space: nowrap;
}

.common-head .title img {
    width: 45px !important;
    height: 45px !important;
    margin-top: -3px;
}

.common-head .title p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin-top: -5px;
    color: #475467;
}

.common-head .ant-btn {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    background: #3166FF;
    border-radius: 5px;
    color: #fff;
    height: 38px;
}

.common-head .black {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    background: #344054;
    border: 1px solid #344054;
    border-radius: 5px;
    color: #fff;
    height: 38px;
    margin-right: 10px;
}

.common-head .ant-input-affix-wrapper {
    max-width: 200px;
    margin-top: 20px;
    height: 35px;
    border-radius: 6px;
    margin-right: 20px;
}

.common-head img {
    width: 25px;
    padding-right: 10px;
}

.common-head .search-img {
    width: 30px;
    margin-right: -5px;
}

.common-table {
    font-family: 'Inter';
    font-style: normal;
    margin: 0px 60px 0px 60px;
    min-height: 350px;
    overflow-x: auto;
    flex: 1;
}

.non-scroll-table{
    margin: 0px 0px 0px 60px !important;
    max-width: fit-content;
}

.scroll-table {
    margin: 0px 60px 0px 0px !important;
}

.non-scroll-table table tr td {
    height: 44px;
}

.scroll-table table tr td {
    height: 44px;
}

.common-table table {
    width: 100%;
    border-left: 1px solid #EAECF0;
    border-right: 1px solid #EAECF0;
    border-radius: 16px;
}

.common-table table thead {
    background-color: #f0f0f0;
    height: 45px !important;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 99;
}

.bulk-upload-btn{
   margin-left: 15px;
}

.common-table table tfoot {
    background-color: #f0f0f0;
    height: 45px !important;
    margin-bottom: 20px !important;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    right: 0; 
}

.common-table table thead tr th {
    color: grey;
    font-weight: 600 !important;
    padding-left: 30px !important;
    padding-right: 20px;
}

.common-table table tfoot tr td {
    color: #101828;
    font-size: 12px !important;
    font-weight: 600 !important;
    overflow: hidden;
    padding-left: 30px !important;
    padding-right: 20px !important;
    padding: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.common-table .red{
    color: #ff4d4f !important;
}

.common-table table tfoot tr td.empty-cell {
    font-size: 12px !important;
    font-weight: 600 !important;
}

.common-table table thead tr {
    font-size: 12px;
    font-weight: 600;
    padding-bottom: 10px;
    text-align: left;
    white-space: nowrap;
}

.common-table table tbody tr td {
    color: #101828;
    font-size: 11px;
    font-weight: 600 !important;
    overflow: hidden;
    padding-left: 30px !important;
    padding-right: 20px !important;
    padding: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.common-table table tbody tr {
    border-bottom: 1px solid #EAECF0;
}

.common-table img {
    width: 25px;
    cursor: pointer;
}

.common-table button {
    background: #75EEAD;
    border-radius: 5px;
    border: none;
    padding: 3px 8px;
    color: #298443;
    font-size: 11px;
}

.common-table .active-btn {
    background: #75EEAD;
    border-radius: 5px;
    border: none;
    padding: 3px 13px;
    color: #298443;
    font-size: 11px;
}

.common-table .event-start {
    background: #27AE60;
    border-radius: 5px;
    border: none;
    padding: 5px 8px;
    color: #FFFFFF;
    font-size: 11px;
    cursor: pointer;
}

.common-table .event-stop {
    background: #EB5757;
    border-radius: 5px;
    border: none;
    padding: 5px 8px;
    color: #FFFFFF;
    font-size: 11px;
    cursor: pointer;
}

.common-table .inactive-btn {
    background: #F9C1C1;
    border-radius: 5px;
    border: none;
    padding: 3px 8px;
    color: #F24343;
    font-size: 11px;
}

.table-pagination {
    height: 12vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 0px 60px 0px 60px;
}

.table-pagination h5 {
    font-family: 'Inter';
    font-style: normal;
    font-size: 13px;
}

.table-pagination .ant-pagination li {
    font-family: 'Inter';
    font-style: normal;
    font-size: 11px;
}

.ant-pagination-item-active,
.ant-pagination-item-active:hover {
    background-color: #3166FF;
    border: 1px solid #3166FF;
}

.ant-pagination-item-active a,
.ant-pagination-item-active:hover a {
    color: #fff;
}

th:first-child {
    border-radius: 0px 0 0 0;
}

th:last-child {
    border-radius: 0 0px 0 0;
}

th:not(:first-child):not(:last-child) {
    border-radius: 0;
}

/*  Common Table & table head */


@media only screen and (max-width: 600px) {

    .common-head {
        text-align: center;
        flex-direction: column !important;
    }

    .common-head .txt-right {
        text-align: center;
        margin-top: 5px;
    }

    .common-table {
        padding: 0px;
        padding-top: 20px;
        flex-direction: column !important;
        height: auto !important;
    }

    .common-head .ant-input {
        max-width: 100%;
        /* margin-top: 20px; */
    }

    .table-pagination {
        height: 11vh;
    }

    .dashboard-partent {
        padding: 20px !important;
    }

    .dashboard-head .ant-select {
        width: 150px !important;
    }
}

@media only screen and (max-width: 768px) {
    .common-head {
        padding: 10px !important;
    }
}


/* antd input start */

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    content: none;
}

.ant-form-item-label>label {
    font-family: 'Inter' !important;
    font-weight: 500;
    font-size: 13px;
    line-height: 40px;
    color: #344054 !important;
    width: 400px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

/* antd input start */

/* antd-select start */


.ant-select {
    max-width: 350px;
}

.ant-select .anticon {
    color: black;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #d0d5dd !important;
    height: auto;
    padding: 5px 10px;
    box-shadow: 0px 0px 1px #00000029;
    border-radius: 5px !important;
    font-size: 12px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
    border: 1px solid #3166FF !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector:focus-within {
    border: 1px solid #3166FF !important;
    box-shadow: 0 0 2px #3166FF !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    font-size: 14px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #5377e1;
    color: white;
}

/* antd-select end */


/*  popover Start */

/* .ant-popover-message-icon {
    color: red !important;
} */

.ant-popover-buttons .ant-btn {
    border-radius: 6px;
    padding: 0px 10px;
}

/* popover end */


.ant-btn:focus,
.ant-btn:hover {
    color: none !important;
}

.ant-btn:active>span,
.ant-btn:focus>span {
    color: none !important;
}


/* antd-modal start */

.ant-modal-body {
    padding: 14px 10px 0px 10px !important;
}

.ant-modal-content {
    border-radius: 10px !important;
    padding: 10px 10px;
}

.ant-modal-body .ant-form-item .ant-form-item-label>label {
    margin-bottom: -10px !important;
}

/* antd-modal end */
.table-pagination .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 1px 10px !important;
}

.ant-select-selection-item {
    font-size: 12px !important;
}

.ant-switch.ant-switch-checked {
    background-color: #75EEAD !important;
}

.ant-switch {
    padding: 0px !important;
    background-color: rgba(0,0,0,.25) !important;
    border-radius: 100px !important;
}

.ant-btn.ant-btn-default.active-button {
    background-color: #27AE60;
    margin-left: 5px;
}

.ant-btn.ant-btn-default.inactive-button {
    background-color: #EB5757;
    margin-left: 5px;
}

/* antd-checkbox Start */

.ant-checkbox .ant-checkbox-inner {
    border-radius: 30px;
    border-color: red;
}


.ant-checkbox-checked .ant-checkbox-inner {
    border-color: green !important;
    background-color: #fff;
}

.ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: green !important;
    color: green;
}

.ant-checkbox:hover .ant-checkbox-inner {
    border-color: red !important;
    cursor: pointer;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: red !important;
}

.ant-checkbox-checked:hover .ant-checkbox-inner {
    border-color: green !important;
    cursor: pointer;
    border-radius: 30px !important;
}

.ant-checkbox-input {
    background-color: #3166FF !important;
}



