.csv {
    border-radius: inherit;
    margin-left: 2%;
    height: 31px !important;
}

.common-head .ant-picker-input input {
    font-size: 14px;
}

.common-head .ant-picker {
    font-size: 14.5px;
    border-radius: 5px;
    min-width: 200px;
}

.common-head .select .ant-select-selector {
    width: 260px;
    text-align: left;
    padding: 0px;
    padding-left: 10px;
}

.empty-style {
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    left: 45%;
    top: 55%;
}

.common-table table tbody tr td.shift-out-cell {
    background-color: rgb(255, 73, 73) !important;
    color: white !important;
}

.common-table table tbody tr td.holiday-cell {
    background-color: rgb(231, 231, 88) !important;
    color: black !important;
}

.common-table table tbody tr td.holiday-late-entry-cell {
    background-color: rgb(231, 231, 88) !important;
    color: red !important;
}

.common-table table tbody tr td.default-cell {
    background-color: white !important;
}

.common-head .ant-btn[disabled] {
    background-color: #d0d0d0;
}

.search-dwell .ant-input-affix-wrapper {
    margin-top: 0px !important;
}

.search-dwell .ant-select {
    position: relative;
    top: 12px;
}

.search-dwell .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.select .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0px !important;
    padding-left: 10px !important;
}

.select .ant-select {
    margin-left: 20px;
}

.report-search {
    margin-right: 0px !important;
    max-width: 250px !important;
}






.common-heads {
    padding: 20px 60px 20px 60px;
    align-items: center;
}

.common-heads .title h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 36px;
    color: #101828;
    white-space: nowrap;
}

.common-heads .title img {
    width: 45px !important;
    height: 45px !important;
    margin-top: -3px;
}

.common-heads .title p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin-top: -5px;
    color: #475467;
}

.common-heads .ant-btn {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    background: #3166FF;
    border-radius: 5px;
    color: #fff;
    height: 38px;
    width: 100%;
}

.common-heads .black {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    background: #344054;
    border: 1px solid #344054;
    border-radius: 5px;
    color: #fff;
    height: 38px;
    margin-right: 10px;
}

.common-heads .ant-input-affix-wrapper {
    border-radius: 6px;
    width: 100%;
}

.common-heads img {
    width: 25px;
    padding-right: 10px;
}

.common-heads .search-img {
    width: 30px;
    margin-right: -5px;
}

.common-heads .ant-picker {
    width: 100%;
    border-radius: 6px;
}

.common-heads .ant-select {
    width: 100%;
}

.common-heads .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0px !important;
    padding-left: 10px !important;
}

.scrollable-table {
    display: flex;
}
.hour-table{
    height: 420px;
}

.hour-table tfoot{
    position: sticky;
    bottom: 0px;
}